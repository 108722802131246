<template>
  <!-- <b-card> -->
    

    <vue-apex-charts
      type="donut"
      height="250"
      width="250"
      :options="data.chartOptions"
      :series="data.series"
       
    />
  <!-- </b-card> -->
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  props: ['data'],
  components: {
    VueApexCharts,
  },
  data() {
    return {
      apexChatData,
    }
  },
}
</script>
