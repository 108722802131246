<template>
  <div>
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-col>
        <h1 class="brand-text text-warning ml-1">
          موبايل مصر <span class="logo-span badge btn-info "> بائعين</span>
        </h1>
      </b-col>
    </b-row>
    <b-container
      class="bv-example-row bv-example-row-flex-cols"
      v-if="data != null"
    >
      <b-row align-v="stretch">
        <b-col md="3" class="mb-3">
          <b-card
            class="h-100 m-1 mb-3 pb-0 rounded d-flex flex-column"
            style="position:relative"
          >
            <h1 class="h2 text-secondary title-card font-weight-bold">
              معلومات شخصية
            </h1>
            <div>
              <p class="text-primary h4">إسم الشركة</p>
              <p class="h4 text-secondary font-weight-bold">
                {{ data.storename }}
              </p>
            </div>
            <div>
              <p class="text-primary h4">إسم المسؤل</p>
              <p class="h4 text-secondary" v-if="data.internal_manager_name">
                {{ data.internal_manager_name }}
              </p>
              <p class="h4 text-secondary" v-if="!data.internal_manager_name">
                لايوجد
              </p>
            </div>
            <div
              class="d-flex justify-content-end align-items-center btn-container"
            >
              <b-button
                pill
                variant="outline-secondary"
                :to="{ path: 'vendor/update' }"
                class="text-primary"
                >تعديل</b-button
              >
            </div>
          </b-card>
        </b-col>
        <b-col md="3" class="mb-3">
          <b-card class="h-100 m-1  rounded" style="position:relative">
            <h1 class="h2 text-secondary title-card font-weight-bold">
              الرصيد
            </h1>
            <div>
              <p class="text-primary h4">
                <router-link to="/orders/pending-amount/"
                  >رصيد قيد التحصيل</router-link
                >
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.vendor_wallet.pending_amount }} جنية
              </p>
            </div>
            <div>
              <p class="text-primary h4">
                <router-link to="/transaction/received-amount"
                  >رصيد مسدد من قبل</router-link
                >
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.vendor_wallet.received_amount }} جنية
              </p>
            </div>
            <div>
              <p class="text-primary h4">
                <router-link to="/orders/settled/"
                  >رصيد متاح للتحصيل</router-link
                >
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.vendor_wallet.available_amount }} جنية
              </p>
            </div>
            <div
              class="d-flex justify-content-end align-items-center btn-container"
            >
              <b-button
                pill
                variant="outline-secondary"
                :to="{ path: 'transaction/add' }"
                class="text-primary"
                >سحب</b-button
              >
            </div>
          </b-card>
        </b-col>
        <b-col md="3" class="mb-3">
          <b-card class="h-100 m-1 rounded" style="position:relative">
            <h1 class="h2 text-secondary title-card font-weight-bold">
              منتجاتك
            </h1>
            <div>
              <p class="text-primary h4">
                <router-link to="/variants/enabled"
                  >المنتجات المعروضة حالياً</router-link
                >
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.enabled_products }}
              </p>
            </div>
            <div>
              <p class="text-primary h4">
                <router-link to="/variants/review">قيد الموافقة</router-link>
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.review_products }}
              </p>
            </div>
            <div>
              <p class="text-primary h4">
                <router-link to="/variants/disabled">المرفوضة</router-link>
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.disabled_products }}
              </p>
            </div>
            <div
              class="d-flex justify-content-end align-items-center btn-container"
            >
              <b-button
                pill
                variant="outline-secondary"
                v-if="type == 'seller'"
                :to="{ path: 'Used/Product/Index' }"
                class="text-primary"
                >مشاهدة</b-button
              >
              <b-button
                pill
                variant="outline-secondary"
                v-else
                :to="{ path: 'Product/Index' }"
                class="text-primary"
                >مشاهدة</b-button
              >
            </div>
          </b-card>
        </b-col>
        <b-col md="3" class="mb-3">
          <b-card class="h-100 m-1 rounded" style="position:relative">
            <h1 class="h2 text-secondary title-card font-weight-bold">
              طلبياتك
            </h1>
            <div>
              <p class="text-primary h4">
                <router-link to="/orders/pending"
                  >قيد انتظار الموافقة</router-link
                >
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.pending_orders }}
              </p>
            </div>
            <div>
              <p class="text-primary h4">
                <router-link to="/orders/ready-to-shipped"
                  >قيد انتظار الشحن</router-link
                >
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.on_hold_orders }}
              </p>
            </div>
            <div>
              <p class="text-primary h4">
                <router-link to="/orders/shipped">المستلمة</router-link>
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.shipped_orders }}
              </p>
            </div>
            <div
              class="d-flex justify-content-end align-items-center btn-container"
            >
              <b-button
                pill
                variant="outline-secondary"
                :to="{ path: 'orders/OrderIndex' }"
                class="text-primary"
                >مشاهدة</b-button
              >
            </div>
          </b-card>
        </b-col>
        <b-col md="3" class="mb-3">
          <b-card class="h-100 m-1 rounded" style="position:relative">
            <h1 class="h2 text-secondary title-card font-weight-bold">
              تقرير شهرى
            </h1>
            <apex-donut-chart :data="donutChart" />
            <div
              class="d-flex justify-content-end align-items-center btn-container"
            >
              <b-button
                pill
                variant="outline-secondary"
                :to="{ path: 'orders/OrderIndex' }"
                class="text-primary"
                >مشاهدة</b-button
              >
            </div>
          </b-card>
        </b-col>
        <b-col md="3" class="mb-3">
          <b-card class="h-100 m-1 rounded" style="position:relative">
            <h1 class="h2 text-secondary title-card font-weight-bold">
              مبيعاتك
            </h1>
            <div>
              <p class="text-primary h4">مبيعات الاسبوع الحالى</p>
              <p class="h4 text-secondary text-right">
                {{
                  data.weekly_selling.sum == null ? 0 : data.weekly_selling.sum
                }}
              </p>
            </div>
            <div>
              <p class="text-primary h4">مبيعات هذا الشهر</p>
              <p class="h4 text-secondary text-right">
                {{
                  data.month_selling.sum == null ? 0 : data.month_selling.sum
                }}
              </p>
            </div>
            <div>
              <p class="text-primary h4">إجمالى المبيعات</p>
              <p class="h4 text-secondary text-right">
                {{ data.total_selling.sum }}
              </p>
            </div>
            <div
              class="d-flex justify-content-end align-items-center btn-container"
            >
              <b-button
                pill
                variant="outline-secondary"
                :to="{ path: 'orders/OrderIndex' }"
                class="text-primary"
                >مشاهدة</b-button
              >
            </div>
          </b-card>
        </b-col>
        <b-col md="3" class="mb-3">
          <b-card class="h-100 m-1 rounded" style="position:relative">
            <h1 class="h2 text-secondary title-card font-weight-bold">
              مرتجعاتك
            </h1>
            <div>
              <p class="text-primary h4">
                <router-link to="/orders/refund/index/pending"
                  >قيد المراجعه</router-link
                >
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.pending_refund }}
              </p>
            </div>
            <div>
              <p class="text-primary h4">
                <router-link to="/orders/refund/index/accept"
                  >مرتجع</router-link
                >
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.accepted_refund }}
              </p>
            </div>
            <div>
              <p class="text-primary h4">
                <router-link to="/orders/refund/index/cancel"
                  >تم الرفض</router-link
                >
              </p>
              <p class="h4 text-secondary text-right">
                {{ data.cancelled_refund }}
              </p>
            </div>
            <div
              class="d-flex justify-content-end align-items-center btn-container"
            >
              <b-button
                pill
                variant="outline-secondary"
                :to="{ path: '/orders/refund/index' }"
                class="text-primary"
                >مشاهدة</b-button
              >
            </div>
          </b-card>
        </b-col>
        <b-col md="3" class="mb-3">
          <b-card class="h-100 m-1 rounded">
            <h1 class="h2 text-secondary title-card font-weight-bold">
              إضافة منتج
            </h1>
            <div>
              <p class="text-primary h4">إضافة منتج جديد للمتجر</p>
            </div>
            <div class="text-center mt-2">
              <div>
                <p class="text-primary h3 font-weight-bold text-center  mt-5">
                  من هنا
                </p>
              </div>
              <div
                class="d-flex justify-content-center align-items-center d-block"
                style=""
              >
                <b-button
                  variant="secondary"
                  class="btn-icon rounded-circle p-0 d-flex justify-content-center align-items-center  d-block"
                  v-if="type == 'seller'"
                  :to="{ path: '/Product/Add' }"
                  style="width:100px; height:100px;"
                >
                  <div class="">
                    <feather-icon
                      icon="PlusIcon"
                      style="width: 50px; height:50px;"
                    />
                  </div>
                </b-button>
                <b-button
                  variant="flat-secondary d-block"
                  class="btn-icon rounded-circle p-0"
                  v-else
                  :to="{ path: '/Product/Add' }"
                  style="width:100px; height:100px;"
                >
                  <b-img
                    src="@/assets/images/add-button.png"
                    class="w-100 h-100"
                  ></b-img>
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container
    v-if="data != null"
        class="bv-example-row bv-example-row-flex-cols"
      
      >
        <b-row align-v="stretch">
            <b-col class="mb-2" md="12" sm="12">
          <!-- <div v-for="(produc, index) in productsR" :key="index"></div> -->
          <CardTopProducts
            class="h-100"
            :title="$t('Product_Index.Products')"
            :color="'color:#003F91'"
            :paginationOption="false"
          />
          
          
        </b-col>
       
        </b-row>
      </b-container>

  </div>
</template>

<script>
import ApexDonutChart from "./chart/ApexDonutChart.vue";
  import CardTopProducts from '@/Components/card/CardTopProducts.vue'
// import CardAdvanceStatesB from '@/Components/card/CardAdvanceStatesB.vue'
export default {
  components: {
      CardTopProducts,
    ApexDonutChart,
    // CardAdvanceStatesB,
  },
  created() {
    this.type = localStorage.getItem("vType");
    this.fetchData();
  },

  methods: {
    fetchData() {
      axios
        .get("home")
        .then((result) => {
          const data = result.data.data;
          if (String(data.sales_growth).indexOf("-") > -1) {
            this.donutChart.series.push(Math.abs(data.sales_growth));
            this.donutChart.chartOptions.colors.push("#FF0000");
          } else {
            this.donutChart.series.push(Math.abs(data.sales_growth));
            this.donutChart.chartOptions.colors.push("#046d6d");
          }
          this.data = data;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
  },
  data() {
    return {
      type: null,
      data: null,
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: [],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: " زيادة المبيعات",
                    // formatter() {
                    //   return '31%'
                    // },
                  },
                },
              },
            },
          },
          labels: ["المبيعات"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },

                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    };
  },
};
</script>

<style lang="scss">
foreignObject {
  height: 200px;
}
#SvgjsSvg1001 {
  height: 230px;
}
.apexcharts-legend.position-bottom.apexcharts-align-center,
.apexcharts-legend.position-top.apexcharts-align-center {
  display: none;
}
.btn-container {
  position: absolute;
  bottom: 10px;
  right: 20px !important;
}
.apexcharts-pie-label,
.apexcharts-datalabels,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
  letter-spacing: normal;
}
@media only screen and (max-width: 425px) {
  .brand-text {
    font-size: 20px;
  }
  .title-card {
    font-size: 20px;
  }
}
</style>
